import React from "react"

export const TitleUnderlineWebchatOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="220" height="10" viewBox="0 0 220 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.9014 10C36.353 9.07169 3.04854 10.1081 0.627772 9.82831C-1.34856 9.45317 1.80979 2.84689 3.64428 1.87407C4.2873 1.52436 5.03433 1.47351 6.74589 1.63247C9.4598 1.93131 44.5515 1.33997 44.5515 1.33997C45.0149 1.54344 46.4049 0.856744 46.6602 1.10472C47.464 1.68332 104.343 0.894891 109.194 0.8631C112.134 0.844025 109.808 1.23824 113.269 1.07928C120.645 0.761364 136.72 1.06657 142.309 0.704142C146.782 0.405302 147.746 0.392594 152.039 0.63421C156.427 0.850392 179.755 0.538832 182.422 0.074676C183.074 -0.0524901 183.576 -0.00797814 183.576 0.138263C183.576 0.170054 200.02 0.278142 200.095 0.392591C200.218 0.538832 200.719 0.608769 201.183 0.507036C202.043 0.354437 219.547 -0.198737 219.821 0.227269C220.681 1.44806 218.27 6.64915 216.237 7.91446C214.157 9.26878 202.734 7.74915 193.552 8.56302L150.801 8.64568L141.638 9.15434C140.588 8.71561 136.55 9.82196 134.111 9.03353C133.08 8.72197 125.799 8.67746 124.191 9.00809C122.792 9.26242 85.5438 8.62023 81.7236 9.50403C74.9151 8.76647 47.3032 9.82197 40.9014 10Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineWebchatTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="151" height="10" viewBox="0 0 151 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.0733 10C24.9514 9.07169 2.09241 10.1081 0.43088 9.82831C-0.925602 9.45317 1.24217 2.84689 2.5013 1.87407C2.94264 1.52436 3.45538 1.47351 4.63013 1.63247C6.49286 1.93131 30.5785 1.33997 30.5785 1.33997C30.8966 1.54344 31.8506 0.856744 32.0259 1.10472C32.5776 1.68332 71.617 0.894891 74.9465 0.8631C76.965 0.844025 75.3684 1.23824 77.7439 1.07928C82.8063 0.761364 93.8399 1.06657 97.6757 0.704142C100.746 0.405302 101.408 0.392594 104.354 0.63421C107.366 0.850392 123.377 0.538832 125.208 0.074676C125.656 -0.0524901 126 -0.00797814 126 0.138263C126 0.170054 137.286 0.278142 137.338 0.392591C137.423 0.538832 137.767 0.608769 138.085 0.507036C138.675 0.354437 150.689 -0.198737 150.877 0.227269C151.468 1.44806 149.813 6.64915 148.417 7.91446C146.989 9.26878 139.149 7.74915 132.847 8.56302L103.504 8.64568L97.2149 9.15434C96.4945 8.71561 93.7231 9.82196 92.0486 9.03353C91.3411 8.72197 86.3436 8.67746 85.2402 9.00809C84.2796 9.26242 58.7142 8.62023 56.0921 9.50403C51.419 8.76647 32.4672 9.82197 28.0733 10Z" fill="#FFBA00" />
    </svg>
  </span>
)