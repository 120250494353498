/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Culqi from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import IttsaBus from "@components/sharedComponents/customersLogos/logos/IttsaBus.svg"
import Chiolecca from "@components/sharedComponents/customersLogos/logos/Chiolecca.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/whatsapp-vs-email.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/proceso-verificacion-usuarios.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/marketing-automation.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/expectativas-cliente.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/ejemplos-marketing-conversacional.jpg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageChannels/pageEmail/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageChannels/pageEmail/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageChannels/pageEmail/images/accordionLeft/imageThree.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageEmail.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageChannels/pageEmail/conversions/images/imageOne.webp"
import imageConversionsTwo from "@components/pageChannels/pageEmail/conversions/images/imageTwo.webp"
import imageConversionsThree from "@components/pageChannels/pageEmail/conversions/images/imageThree.webp"
import imageConversionsFour from "@components/pageChannels/pageEmail/conversions/images/imageFour.webp"

import { TitleUnderlineWebchatOne, TitleUnderlineWebchatTwo } from "@components/pageChannels/components/intro/titleUnderlineWebchat"
import imageStartEmail from "@components/pageChannels/components/intro/images/imageStartEmail.webp"

import ImageVerticalOne from "@components/pageChannels/pageEmail/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalTwo from "@components/pageChannels/pageEmail/images/imagesNavbarVertical/imageTwo.webp"
import ImageVerticalFour from "@components/pageChannels/pageEmail/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFive from "@components/pageChannels/pageEmail/images/imagesNavbarVertical/imageFour.webp"

const titleStart = (
  <p>
    Automatiza tus campañas de  <TitleUnderlineWebchatTwo underline="email" /> <TitleUnderlineWebchatOne underline=" marketing" />  con Inteligencia Artificial
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Despliega y crea fácilmente campañas de correos electrónicos ya sea para atención al cliente, marketing y ventas. Utiliza la IA para crear textos únicos que impulsen la conversión.
        <br /><br />
        Crea workflows y segmentaciones automáticas para adaptar la comunicación con tus clientes a sus necesidades específicas.

      </p>
    ),
    image: imageStartEmail,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Eriza,
    Conexo,
    Culqi,
    Alfin,
    IttsaBus,
    Chiolecca,
  ],
  customersTablet: {
    arrayOne: [Eriza, Conexo, Culqi, Alfin, IttsaBus, Chiolecca,]
  },
  articles: [
    {
      image: article1,
      title: "WhatsApp vs email marketing: ¿Cuál tiene un mayor impacto?",
      textbody:
        "Creamos un WhatsApp vs Email para conocer sus fortalezas y debilidades de cada uno y así sepas cuál es el ideal para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-vs-email/",
      card: true,
    },
    {
      image: article2,
      title: "Cómo crear mejores procesos de verificación de usuarios y CX",
      textbody:
        "Evita procesos engorrosos en la verificación de usuarios con estos 6 consejos enfocados en la experiencia de cliente (CX).",
      link: "https://beexcc.com/blog/proceso-verificacion-usuarios/",
      card: true,
    },
    {
      image: article3,
      title: "Cómo alcanzar la automatización en tus campañas de marketing digital",
      textbody:
        "Descubre estrategias clave y herramientas innovadoras para optimizar tu marketing digital y lograr una automatización efectiva.",
      link: "https://beexcc.com/blog/marketing-automation/",
    },
    {
      image: article4,
      title: "¿Cómo mejorar las expectativas de un cliente? 7 consejos",
      textbody:
        "Descubre las mejores prácticas para mejorar las expectativas de un cliente con esta guía completa. ¡ENTRA AQUÍ y cautiva a tus clientes!",
      link: "https://beexcc.com/blog/expectativas-cliente/",
    },
    {
      image: article5,
      title: "10 ejemplos de marketing conversacional para tu empresa",
      textbody:
        "¿Buscas ejemplos de marketing conversacional? Conoce su significado y cómo puedes implementarlo. ¡ENTRA AQUÍ y eleva la experiencia del cliente!",
      link: "https://beexcc.com/blog/ejemplos-marketing-conversacional/",
    },
  ],
  contacts: {
    title: "Aumenta el ROI de tus campañas de email marketing",
    textbody: "Nuestros asesores estarán dispuestos para que puedas mejorar el rendimiento de tus campañas de mailing.",
    href: "/hablemos/",
    btn: "Hablar como un experto",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  accordionLeft: [
    {
      title: "Workflows con IA",
      description: "<p>Utiliza la IA para crea a workflows automatizados para enviar mensajes más rápido como promociones de marketing, mensajes de descuento, recordación de pagos, entre otros. Envía mensajes mucho más rápido y reduce tareas manuales.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Integraciones",
      description: "<p>Integra herramientas de terceros como HubSpot, Salesforce, Zoho u otros. Incluso CSM como Woocommerce, Shopify, WordPress y mucho más. Usa APIs escalables y permite acciones como la actualización inmediata de datos o notificar ante carritos abandonados.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Enfoque omnicanal",
      description: "<p>Integra tus campañas de email con otros canales para ofrecer una experiencia de cliente 360. Adhiere cualquier servicio de correo con WhatsApp, redes sociales, SMS y más. Asegura que tus clientes reciban la información adecuada en el momento adecuado.</p>",
      image: ImageAccordionLeftThree,
    },
  ],
  bannerVideo: {
    title: "Cómo automatizar procesos en una empresa: 5 consejos claves",
    description: "<p>Descubre cómo optimizar la eficiencia de tu empresa con estos 5 consejos esenciales para la automatización de procesos. Simplifica operaciones, aumenta la productividad y libera tiempo para lo que realmente importa. <br /> <br /> Nuestro Project Manager, Jheyson Portocarrero, nos cuenta todos los detalles para automatizar cada proceso de tu empresa.</p>",
    image: imageVideo
  },
  conversions: [
    {
      title: "Obtén ideas con la IA",
      img: imageConversionsOne,
      text:
        "Aprovecha el poder de la inteligencia artificial para generar ideas innovadoras para tus campañas de email. Nuestra plataforma utiliza IA para analizar datos y sugerir contenidos, líneas de asunto y momentos óptimos para enviar tus correos, asegurando que siempre estés un paso adelante.",
    },
    {
      title: "Crea estrategias A/B",
      img: imageConversionsTwo,
      text:
        "Optimiza el rendimiento de tus campañas con pruebas A/B. Experimenta con diferentes versiones de tus correos electrónicos para determinar qué elementos resuenan mejor con tu audiencia, mejorando continuamente tus resultados de marketing.",
    },
    {
      title: "Segmenta tus envíos",
      img: imageConversionsThree,
      text:
        "Dirige tus mensajes a los segmentos de audiencia más relevantes. Personaliza tus correos electrónicos para diferentes grupos basándote en comportamiento, demografía, historial de compras y más, asegurando una comunicación más efectiva.",
    },
    {
      title: "Utiliza recursos interactivos",
      img: imageConversionsFour,
      text:
        "Haz que tus correos electrónicos sean más atractivos con recursos interactivos. Añade encuestas, cuestionarios, vídeos y otros elementos interactivos para fomentar la participación y aumentar el compromiso de tus destinatarios.",
    },
  ],
  navbarVerticalRight: [
    {
      tab: "1",
      title: "Mensajes de Bienvenida",
      text: (
        <p>
          Haz que cada nuevo cliente se sienta valorado desde el primer momento con mensajes de bienvenida automatizados. Fideliza más utilizando plantillas de bienvenida ya sea al crear una nueva cuenta o cuando sea un nuevo cliente.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Recordatorios personalizados",
      text: (
        <p>
          Aumenta la relevancia y el compromiso con recordatorios personalizados. Ya sea para carritos abandonados, renovaciones de suscripción o eventos especiales; automatiza el envío de mensajes para que tus clientes reciban la información que necesitan en el momento justo.
        </p>
      ),
      image: ImageVerticalTwo,
    },
  ],
  navbarVerticalLeft: [
    {
      tab: "3",
      title: "Fideliza mejor a tus clientes",
      text: (
        <p>
          Fortalece la lealtad de tus clientes con campañas de email dirigidas a fomentar la fidelización. Ofrece contenido exclusivo, promociones especiales y agradecimientos personalizados para mantener a tus clientes comprometidos y satisfechos a largo plazo.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "4",
      title: "Mejora estrategias de cross/up selling",
      text: (
        <p>
          Impulsa tus ventas con estrategias efectivas de cross-selling y up-selling. Utiliza la IA para identificar oportunidades y enviar recomendaciones de productos personalizadas que animen a tus clientes a explorar y adquirir más de tu oferta.
        </p>
      ),
      image: ImageVerticalFive,
    },
  ],
  navbarVerticalSilder: [
    {
      tab: "1",
      title: "Mensajes de Bienvenida",
      text: (
        <p>
          Haz que cada nuevo cliente se sienta valorado desde el primer momento con mensajes de bienvenida automatizados. Fideliza más utilizando plantillas de bienvenida ya sea al crear una nueva cuenta o cuando sea un nuevo cliente.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Recordatorios personalizados",
      text: (
        <p>
          Aumenta la relevancia y el compromiso con recordatorios personalizados. Ya sea para carritos abandonados, renovaciones de suscripción o eventos especiales; automatiza el envío de mensajes para que tus clientes reciban la información que necesitan en el momento justo.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Fideliza mejor a tus clientes",
      text: (
        <p>
          Fortalece la lealtad de tus clientes con campañas de email dirigidas a fomentar la fidelización. Ofrece contenido exclusivo, promociones especiales y agradecimientos personalizados para mantener a tus clientes comprometidos y satisfechos a largo plazo.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "4",
      title: "Mejora estrategias de cross/up selling",
      text: (
        <p>
          Impulsa tus ventas con estrategias efectivas de cross-selling y up-selling. Utiliza la IA para identificar oportunidades y enviar recomendaciones de productos personalizadas que animen a tus clientes a explorar y adquirir más de tu oferta.
        </p>
      ),
      image: ImageVerticalFive,
    },
  ],
}

export default data
