import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="171" height="10" viewBox="0 0 171 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.7916 10C28.2562 9.07169 2.36955 10.1081 0.48795 9.82831C-1.0482 9.45317 1.4067 2.84689 2.8326 1.87407C3.3324 1.52436 3.91305 1.47351 5.24339 1.63247C7.35284 1.93131 34.6287 1.33997 34.6287 1.33997C34.9888 1.54344 36.0693 0.856744 36.2677 1.10472C36.8925 1.68332 81.1027 0.894891 84.8732 0.8631C87.1591 0.844025 85.351 1.23824 88.0411 1.07928C93.7741 0.761364 106.269 1.06657 110.613 0.704142C114.089 0.405302 114.839 0.392594 118.176 0.63421C121.586 0.850392 139.719 0.538832 141.792 0.074676C142.299 -0.0524901 142.688 -0.00797814 142.688 0.138263C142.688 0.170054 155.47 0.278142 155.529 0.392591C155.624 0.538832 156.014 0.608769 156.374 0.507036C157.043 0.354437 170.648 -0.198737 170.861 0.227269C171.53 1.44806 169.655 6.64915 168.075 7.91446C166.458 9.26878 157.579 7.74915 150.443 8.56302L117.213 8.64568L110.091 9.15433C109.275 8.71561 106.137 9.82196 104.24 9.03353C103.439 8.72197 97.7798 8.67745 96.5303 9.00809C95.4425 9.26242 66.4909 8.62023 63.5215 9.50403C58.2295 8.76647 36.7675 9.82197 31.7916 10Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="237" height="10" viewBox="0 0 237 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.062 10C39.1621 9.07169 3.28411 10.1081 0.676282 9.82831C-1.45277 9.45317 1.94964 2.84689 3.92588 1.87407C4.61859 1.52436 5.42334 1.47351 7.26716 1.63247C10.1908 1.93131 47.9941 1.33997 47.9941 1.33997C48.4933 1.54344 49.9907 0.856744 50.2658 1.10472C51.1317 1.68332 112.405 0.894891 117.631 0.8631C120.799 0.844025 118.293 1.23824 122.022 1.07928C129.968 0.761364 147.285 1.06657 153.306 0.704142C158.124 0.405302 159.163 0.392594 163.788 0.63421C168.515 0.850392 193.645 0.538832 196.518 0.074676C197.221 -0.0524901 197.761 -0.00797814 197.761 0.138263C197.761 0.170054 215.476 0.278142 215.557 0.392591C215.69 0.538832 216.23 0.608769 216.729 0.507036C217.656 0.354437 236.512 -0.198737 236.807 0.227269C237.734 1.44806 235.136 6.64915 232.946 7.91446C230.705 9.26878 218.399 7.74915 208.508 8.56302L162.453 8.64568L152.582 9.15433C151.452 8.71561 147.102 9.82196 144.474 9.03353C143.363 8.72197 135.519 8.67745 133.788 9.00809C132.28 9.26242 92.154 8.62023 88.0386 9.50403C80.704 8.76647 50.9585 9.82197 44.062 10Z" fill="#FFA700" />
    </svg>
  </span>
)
