import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import { Link } from "gatsby"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Personaliza tus mensajes",
      description: "Envía mensajes altamente personalizados que resuenen con cada segmento de tu audiencia. Utiliza nuestras capacidades avanzadas de segmentación y personalización con IA para asegurarte de que cada correo electrónico sea relevante y atractivo, mejorando las tasas de apertura y clics.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Crea correos sin esfuerzo",
      description: "Diseña y envía correos electrónicos atractivos sin complicaciones. Nuestra intuitiva interfaz de arrastrar y soltar facilita la creación de correos electrónicos con altos índices de conversión, incluso si no tienes experiencia en diseño.",
      image: imageTwoMobile,
      background: "#FADEDD"
    },
    {

      title: "Supervisa tus envíos en tiempo real",
      description: "Identifica oportunidades de venta cruzada o adicional, basándote en el comportamiento de compra y las preferencias del cliente. Ofrece recomendaciones personalizadas durante las interacciones para maximizar el valor del cliente a largo plazo.",
      image: imageThreeMobile ,

      background: "#EEEAFB"
    },
    {
      title: "Utiliza plantillas preconfiguradas",
      description: "Ahorra tiempo con nuestras plantillas preconfiguradas diseñadas para diversas necesidades de marketing, atención al cliente, ventas o cobranza. Personaliza fácilmente estas plantillas para alinearlas con tu marca y mensaje, asegurando consistencia y calidad en todas tus campañas.",
      image: imageFourMobile,
      background: "#FADEDD"
    },
  ]

  return (
    <section className="container-experience-webchat">
      <p className="container-experience-webchat-title">
        Diseñamos la tecnología adecuada para que <TitleUnderlineOne underline="mejores la" /> <TitleUnderlineTwo underline="comunicación" />  con tus clientes</p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-webchat-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-webchat-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
          <section  >
            <p className="container-experience-webchat-cardsOne-card-title">Personaliza tus mensajes</p>
            <p className="container-experience-webchat-cardsOne-card-description">
              Envía mensajes altamente personalizados que resuenen con cada segmento de tu audiencia. Utiliza nuestras capacidades avanzadas de segmentación y personalización con IA para asegurarte de que cada correo electrónico sea relevante y atractivo, mejorando las tasas de apertura y clics.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-webchat-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-webchat-cardsOne-column">
          {/* columna */}
          <section className="container-experience-webchat-cardsOne-card" style={{ background: "#FADEDD", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-webchat-cardsOne-card-title">Crea correos sin esfuerzo</p>
              <p className="container-experience-webchat-cardsOne-card-description">
                Diseña y envía correos electrónicos atractivos sin complicaciones. Nuestra intuitiva interfaz de arrastrar y soltar facilita la creación de correos electrónicos con altos índices de conversión, incluso si no tienes experiencia en diseño.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-webchat-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-webchat-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-webchat-cardsTwo-card" style={{ background: "#FADEDD", padding: "32px 32px 0px" }}>
          <section>
            <p className="container-experience-webchat-cardsTwo-card-title">Utiliza plantillas preconfiguradas</p>
            <p className="container-experience-webchat-cardsTwo-card-description">
              Ahorra tiempo con nuestras plantillas preconfiguradas diseñadas para diversas necesidades de marketing, atención al cliente, ventas o cobranza. Personaliza fácilmente estas plantillas para alinearlas con tu marca y mensaje, asegurando consistencia y calidad en todas tus campañas.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-webchat-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-webchat-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-webchat-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-webchat-cardsTwo-card-title">Supervisa tus envíos en tiempo real</p>
              <p className="container-experience-webchat-cardsTwo-card-description">
                Mantente informado sobre el rendimiento de tus campañas con reportes en tiempo real. Supervisa tasas de apertura, clics, conversiones y otros indicadores clave para ajustar tus estrategias sobre la marcha y maximizar el impacto de tus campañas.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-webchat-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-webchat-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-webchat-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-webchat-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-webchat-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-webchat-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
